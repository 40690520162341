import { useEffect, useState } from "react";
import React from "react";
import {
  Box,
  Text,
  Link,
  VStack,
  Flex,
  Image,
  Heading,
  Collapse,
  FormControl,
  Stack,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  HStack,
  Divider,
  Spinner,
  useColorModeValue,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  PinInput,
  PinInputField,
  Alert,
  AlertIcon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  AbsoluteCenter,
  ModalCloseButton,
  ModalFooter,
  Textarea,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { AfipIcon, AutenticaIcon } from "../../components/Icons";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../../utilities/Forms";
import { useDispatch, useSelector } from "react-redux";
import { doubleFactor, generateToken, getCurrentProfile, login } from "./_duck";
import { AppDispatch } from "../../store/StoreRoot";
import { resetState } from "./_duck/registerSlice";
import { SpinnerLogin } from "../../components/SpinnerLogin";
import { Select as SelectChakra, OptionBase } from "chakra-react-select";
import { getCalles } from "./_duck/callesSlice";


export const LoginFormToken = () => {
  const { token } = useParams();
  // Chakra color mode
  const titleColor = useColorModeValue("muni.celeste", "teal.200");

  const navigate = useNavigate();

  const [dni, setDni] = useState("");
  const dispatch: AppDispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [pinNumber, setPinNumber] = useState("");
  const initialRef = React.useRef(null);
  const [email, setEmail] = useState("");
  const [showPinInput, setShowPinInput] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  interface SelectedOption {
    label: string;
    value: string | number; // Ajusta el tipo según el tipo real de value
  }


  const [selectedStreet, setSelectedStreet] = useState<SelectedOption | null>(
    null
  );


  const [VisibleAlertPinError, setVisibleAlertPinError] = useState(false);
  const { loading, error: hasError } = useSelector((resp: any) => resp.profile);
  const { calles, loading: loadingCalles } = useSelector(
    (resp: any) => resp.calles
  );


  const [loadingProfile, setLoadingProfile] = useState(true);
  const { isOpen: isOpenProveedores, onToggle: onToggleProveedores } =
    useDisclosure();
  const {
    isOpen: isOpenPolitica,
    onOpen: onOpenPolitica,
    onClose: onClosePolitica,
  } = useDisclosure();


  const toast = useToast();
  const toastIdRef = React.useRef();


  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };


  const authenticate = (e: any) => {
    onClose();
    e.preventDefault();
    const validate = validateLogin();
    if (validate) {
      sendUser();
      setDni("");
      setPassword("");
      //@ts-ignore
    } else if (!toast.isActive(toastIdRef.current)) {
      if (dni === "" || password === "") {
        setDescriptionError("Por favor, complete los campos requeridos");
        onOpen();
      } else {
        setDescriptionError(
          "Por favor, ingrese datos validos en los campos requeridos"
        );
        onOpen();
      }
    }
  };


  useEffect(() => {
    if (!hasError.isPinError) {
      if (hasError.message && hasError.status !== 403) {
        setDescriptionError(hasError.message);
        onOpen();
        setPassword("");
      }


      if (hasError.status === 403) {
        setCounter(300);
        onOpenModal();
      }
    } else {
      setDescriptionError(hasError.message);
      setVisibleAlertPinError(true);
      setPinNumber("");
      setTimeout(() => {
        setVisibleAlertPinError(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);


  const validateLogin = () => {
    let isValid = true;
    //@ts-ignore
    let validator = Form.validator({
      //@ts-ignore
      dni: {
        value: dni,
        minLength: 6,
        isRequired: true,
      },
      password: {
        value: password,
        isRequired: true,
        minLength: 6,
      },
    });


    if (validator !== null) {
      isValid = false;
    }
    return isValid;
  };


  const sendUser = async () => {
    const formData = {
      dni: "",
      password: "",
      email: "",
    };
    formData.dni = dni;
    formData.password = password;
    formData.email = "";
    try {
      dispatch(login(formData))
        .unwrap()
        .then(async (resplogin) => {
          //@ts-ignore
          dispatch(getCurrentProfile()).then(async (resp) => {
            dispatch(resetState());
            window.location.href = `https://mibahia.gob.ar/login/${resplogin.alpha_code}`;
          });
        });
    } catch (error) {
      setDescriptionError("DNI o contraseña incorrectos.");
      onOpen();
      setPassword("");
    }
  };


  const validateDoubleFactor = async () => {
    try {
      dispatch(doubleFactor({ pin: pinNumber }))
        .unwrap()
        .then(async (resplogin) => {
          //@ts-ignore
          dispatch(getCurrentProfile()).then(async (resp) => {
            window.location.href = `https://mibahia.gob.ar/login/${resplogin.token}`;
          });
        });
    } catch (error) {
      setDescriptionError("DNI o contraseña incorrectos.");
      onOpen();
      setPassword("");
    }
  };


  const [counter, setCounter] = useState(0);
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);


  const { isOpen: isOpenHelp, onToggle: onToggleHelp } = useDisclosure();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  //@ts-ignore
  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };
  //@ts-ignore
  const format = (time) => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60);


    // Get the seconds left after converting minutes
    const seconds = time % 60;


    //Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`;
  };


  const color = useColorModeValue("white", "white");


  useEffect(() => {
    navigate("/")
  }, []);


  if (loadingProfile) {
    //aca va isloading
    return <SpinnerLogin />;
  }


  const coloringresa = "blackAlpha.800";


  const name = token === "habilitaciones" ? "Habilitaciones" : "Desconocido";


  return (
    <>
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpenModal}
          size={"xl"}
          onClose={onClose}
          closeOnOverlayClick={false}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Agustin, tus datos.</ModalHeader>
            <ModalBody pb={6}>
              <HStack>
                <FormControl>
                  <FormLabel>Nombre</FormLabel>
                  <Input
                    ref={initialRef}
                    placeholder="First name"
                    value={"Agustin Ivan"}
                    disabled
                  />
                </FormControl>


                <FormControl>
                  <FormLabel>Apellido</FormLabel>
                  <Input placeholder="Last name" value={"Minnig"} disabled />
                </FormControl>
              </HStack>
              <HStack mt={4}>
                <FormControl>
                  <FormLabel>DNI</FormLabel>
                  <Input
                    placeholder="Last name"
                    value={"44.082.259"}
                    disabled
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Género</FormLabel>
                  <Select placeholder="Selecciona tu género">
                    <option value="masculino">Masculino</option>
                    <option value="femenino">Femenino</option>
                    <option value="otro">Otro</option>
                  </Select>
                </FormControl>
              </HStack>
              <Box position="relative" padding="2" mt={4}>
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  Tu domicilio
                </AbsoluteCenter>
              </Box>
              <FormControl mt={4}>
                <FormLabel>Calle</FormLabel>
                {!loadingCalles ? (
                  <SelectChakra
                    name="calle"
                    options={calles.map((item: any) => {
                      return {
                        label: `${item.nombre} - ${item?.localidad?.abreviatura}`,
                        value: item.id,
                      };
                    })}
                    placeholder="Selecciona tu calle..."
                    closeMenuOnSelect
                    onChange={(selectedOption: SelectedOption | unknown) => {
                      if (
                        typeof selectedOption === "object" &&
                        selectedOption !== null
                      ) {
                        setSelectedStreet(selectedOption as SelectedOption);
                      }
                    }}
                  />
                ) : (
                  <Spinner />
                )}
              </FormControl>
              {selectedStreet && (
                <>
                  <SimpleGrid columns={[1, 2, 3]} spacing={4} mt={4}>
                    <FormControl>
                      <FormLabel>Número de casa</FormLabel>
                      <Input placeholder="Número de casa" />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Piso</FormLabel>
                      <Input placeholder="Piso" />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Número de depto</FormLabel>
                      <Input placeholder="Número de depto" />
                    </FormControl>
                  </SimpleGrid>
                  <FormControl mt={4}>
                    <FormLabel>Observación</FormLabel>
                    <Textarea placeholder="Observación" />
                  </FormControl>
                </>
              )}


              {/* Agregar la sección de contacto */}
              <Box position="relative" padding="2" mt={4}>
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  Tu contacto
                </AbsoluteCenter>
              </Box>
              <FormControl mt={4}>
                <FormLabel>Número de celular (Sin el 0 ni el 15)</FormLabel>
                <InputGroup>
                  <Input
                    type="tel"
                    placeholder="Ingresa tu número de celular"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShowPinInput(true)}
                    >
                      Validar
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              {/* Mostrar el input de pin si showPinInput es verdadero */}
              {showPinInput && (
                <FormControl
                  mt={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FormLabel>Código de verificación</FormLabel>
                  <HStack>
                    <PinInput otp>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </FormControl>
              )}
              <FormControl mt={4}>
                <FormLabel>Correo electrónico</FormLabel>
                <Input
                  type="email"
                  placeholder="Ingresa tu correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button bg="#37BBED" mr={3} w="100%" color={"white"}>
                Guardar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Stack
          flex={1}
          bg={"muni.celeste"}
          justify={"center"}
          spacing={10}
          direction={"column"}
          display={{ base: "none", md: "flex" }}
          align={"center"}
        >
          <Image src={"/slider.png"} />
          <Text
            fontSize="3xl"
            fontWeight={"bold"}
            style={{ color: " white" }}
            width={"40%"}
            textAlign={"center"}
          >
            Registrate y validá tus datos por única vez
          </Text>
        </Stack>
        <Flex flex={1} bg={"white"} justify={"center"}>
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{ userSelect: "none" }}
            w={{ base: "100%", md: "80%", lg: "50%" }}
          >
            <Flex
              direction="column"
              w="100%"
              minH={"90vh"}
              justify={"space-between"}
              p="20px"
              mt={{ md: "0px", lg: "0px" }}
            >
              <Heading color={titleColor} fontSize="48px">
                <Box alignItems={"center"}>
                  <AutenticaIcon w="100%" h="100%" />
                </Box>


                <Text
                  mb="30px"
                  textAlign={"center"}
                  mt="0px"
                  color={coloringresa}
                  fontWeight={400}
                  fontSize={{ base: "22px", md: "20px", lg: "22px" }}
                >
                  Ingresá a{" "}
                  <Text as="span" fontWeight={"bold"} color={"muni.celeste"}>
                    {name}
                  </Text>
                </Text>
              </Heading>
              <Collapse in={isOpen} animateOpacity>
                <Box
                  p="20px"
                  color="white"
                  mb="14px"
                  bg="red.500"
                  rounded="md"
                  shadow="md"
                >
                  <Text
                    fontWeight={"bold"}
                    fontSize={"14px"}
                    textAlign={"center"}
                  >
                    error: {descriptionError}
                  </Text>
                </Box>
              </Collapse>
              <FormControl>
                <Link href="http://auth.mibahia.gob.ar/afiplogin/">
                  <Button
                    fontSize="10px"
                    type="submit"
                    color="white"
                    bg={"muni.verde"}
                    w="100%"
                    h="45"
                    mb="20px"
                    p={6}
                    mt="20px"
                    _hover={{
                      bg: "black",
                      color: "white",
                    }}
                    _active={{
                      bg: "black",
                      color: "white",
                    }}
                  >
                    <Image
                      src="https://www.afip.gob.ar/frameworkAFIP/v1/img/logo_afip_blanco.png"
                      w={"30%"}
                    />
                  </Button>
                </Link>
                {token !== "habilitaciones" ? (
                  <>
                    <Box position="relative" padding="2">
                      <Divider />
                      <AbsoluteCenter bg="white" px="4" fontWeight={"500"}>
                        Otros Proveedores
                      </AbsoluteCenter>
                    </Box>
                    <Link href="http://auth.mibahia.gob.ar/miargentinalogin/">
                      <Button
                        fontSize="10px"
                        type="submit"
                        colorScheme="afip"
                        variant="outline"
                        w="100%"
                        h="45"
                        mb="20px"
                        p={6}
                        mt="20px"
                        _hover={{
                          bg: "black",
                          color: "white",
                        }}
                        _active={{
                          bg: "black",
                          color: "white",
                        }}
                      >
                        <Text fontWeight={"bold"} fontSize={"18px"}>
                          Mi Argentina
                        </Text>
                      </Button>
                    </Link>


                    <Link href="http://auth.mibahia.gob.ar/renaperlogin/">
                      <Button
                        fontSize="10px"
                        type="submit"
                        colorScheme="afip"
                        variant="outline"
                        w="100%"
                        h="45"
                        mb="20px"
                        p={6}
                        mt="20px"
                        _hover={{
                          bg: "black",
                          color: "white",
                        }}
                        _active={{
                          bg: "black",
                          color: "white",
                        }}
                      >
                        <Text fontWeight={"bold"} fontSize={"18px"}>
                          RENAPER
                        </Text>
                      </Button>
                    </Link>
                    <Link href="http://auth.mibahia.gob.ar/anseslogin/">
                      <Button
                        fontSize="10px"
                        type="submit"
                        colorScheme="afip"
                        variant="outline"
                        w="100%"
                        h="45"
                        mb="20px"
                        p={6}
                        mt="20px"
                        _hover={{
                          bg: "black",
                          color: "white",
                        }}
                        _active={{
                          bg: "black",
                          color: "white",
                        }}
                      >
                        <Text fontWeight={"bold"} fontSize={"18px"}>
                          ANSES
                        </Text>
                      </Button>
                    </Link>


                    <Divider />
                  </>
                ) : (
                  <></>
                )}
              </FormControl>
              <FormControl
                alignItems="center"
                justifyContent="space-between"
                alignContent={"center"}
                textAlign={"center"}
                alignSelf={"center"}
                mb="32px"
                mt="10px"
              >
                <Link
                  color={"gray.500"}
                  fontWeight="bold"
                  onClick={() => onOpenPolitica()}
                >
                  Politica de privacidad de datos
                </Link>
              </FormControl>
            </Flex>
          </Flex>
          <Box>
            <Drawer
              placement={"bottom"}
              onClose={onClosePolitica}
              isOpen={isOpenPolitica}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerHeader borderBottomWidth="1px" color={"muni.celeste"}>
                  Politica de Privacidad de Datos
                </DrawerHeader>
                <DrawerBody>
                  <p>Fecha de última actualización: 3 de agosto de 2023</p>
                  <p>
                    El sitio web "autentica.bahia" es gestionado por el Gobierno
                    de Bahía Blanca. Nos preocupamos por la privacidad y
                    seguridad de la información personal de nuestros usuarios.
                    Esta Política de Privacidad describe cómo recopilamos,
                    utilizamos, compartimos y protegemos la información personal
                    que nos proporciona al utilizar nuestro sistema de logueo
                    centralizado.
                  </p>


                  <h2>1. Recopilación de información personal:</h2>
                  <p>
                    Para utilizar los servicios ofrecidos en "autentica.bahia",
                    es necesario registrarse y proporcionar cierta información
                    personal, que puede incluir su nombre, dirección de correo
                    electrónico y contraseña cifrada. Esta información es
                    necesaria para autenticar y autorizar el acceso a los
                    servicios gubernamentales en línea.
                  </p>


                  <h2>2. Uso de la información personal:</h2>
                  <p>
                    La información personal recopilada se utilizará
                    exclusivamente para fines del sistema de logueo
                    centralizado. Su objetivo principal es facilitar un acceso
                    seguro y unificado a los servicios gubernamentales ofrecidos
                    en línea, minimizando la necesidad de múltiples
                    credenciales.
                  </p>


                  <h2>3. Protección de la información personal:</h2>
                  <p>
                    Hemos implementado medidas de seguridad adecuadas para
                    proteger la información personal de los usuarios contra
                    pérdidas, usos no autorizados o accesos indebidos. El acceso
                    a los datos se limita solo a personal autorizado que
                    requiere esta información para el correcto funcionamiento
                    del sistema.
                  </p>


                  <h2>4. Compartir información personal:</h2>
                  <p>
                    La información personal recopilada no será compartida,
                    vendida o transferida a terceros, excepto cuando sea
                    necesario para cumplir con obligaciones legales o
                    regulaciones gubernamentales. La información podrá ser
                    compartida con otras entidades gubernamentales bajo los
                    mismos estándares de protección de privacidad.
                  </p>


                  <h2>5. Retención de datos:</h2>
                  <p>
                    Los datos personales se retendrán únicamente durante el
                    tiempo necesario para cumplir con los fines para los que
                    fueron recopilados, o el tiempo requerido por la ley.
                  </p>


                  <h2>6. Derechos de los usuarios:</h2>
                  <p>
                    Los usuarios tienen derecho a acceder, corregir y eliminar
                    su información personal. Si desean ejercer estos derechos o
                    tienen alguna inquietud sobre la privacidad de sus datos,
                    pueden comunicarse con el departamento de soporte del sitio
                    autentica.bahia.
                  </p>


                  <h2>7. Enlaces a sitios externos:</h2>
                  <p>
                    El sitio web autentica.bahia puede contener enlaces a sitios
                    web de terceros. Esta Política de Privacidad se aplica
                    únicamente al sitio autentica.bahia. Se recomienda a los
                    usuarios revisar las políticas de privacidad de los sitios
                    externos antes de proporcionar información personal.
                  </p>


                  <h2>8. Cambios en la Política de Privacidad:</h2>
                  <p>
                    Esta política puede ser modificada en cualquier momento por
                    el Gobierno de Bahía Blanca. En caso de cambios
                    significativos, se notificará a los usuarios a través de un
                    aviso en el sitio web.
                  </p>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Box>
        </Flex>
      </Stack>
    </>
  );
};



