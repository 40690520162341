import { useEffect } from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Center,
  Spinner,
  Flex,
  CircularProgress,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "../../ColorModeSwitcher"
import { Logo } from "../../Logo"
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../store/StoreRoot";
import { useDispatch } from "react-redux";
import { loginAfip } from "./_duck";

export const LoginByAnses = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    // Obtener la URL actual
    const currentUrl = window.location.href;

    // Extraer los datos de la URL
    const urlParts = currentUrl.split('?');
    if (urlParts.length > 1) {
      const queryString = urlParts[1];
      const params = new URLSearchParams(queryString);
      const stateParam = params.get('state');
      const codeParam = params.get('code');

      // Realizar la solicitud GET para obtener el token
      fetch(`https://auth.mibahia.gob.ar/ansesredirect/?state=${stateParam}&code=${codeParam}`)
        .then((response) => response.json())
        .then((data) => {
          const token = data.token;
          // Realizar el dispatch para iniciar sesión con el token obtenido
          dispatch(loginAfip(token))
            .then(() => {
              // Navegar a la página de Dashboard después de que se complete el dispatch
              window.location.href = `https://mibahia.gob.ar/login/${data.alpha}`;
            })
            .catch((error) => {
              console.error('Error al realizar el dispatch:', error);
            });
        })
        .catch((error) => {
          console.error('Error al obtener el token:', error);
        });
    }
  }, [dispatch, navigate]);

  return (
    <>
      <Center height="100vh">
        <Spinner
          thickness="6px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    </>
  );
};
