import { useEffect, useState } from "react";
import React from "react";
import {
  Box,
  Text,
  Link,
  VStack,
  Flex,
  Image,
  Heading,
  Collapse,
  FormControl,
  Stack,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  HStack,
  Divider,
  Spinner,
  useColorModeValue,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  PinInput,
  PinInputField,
  Alert,
  AlertIcon,
  Checkbox,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  AbsoluteCenter,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Accordion,
  DrawerCloseButton,
  ModalFooter,
  Textarea,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { AfipIcon, AutenticaIcon } from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import Form from "../../utilities/Forms";
import { useDispatch, useSelector } from "react-redux";
import { doubleFactor, getCurrentProfile, login } from "./_duck";
import { AppDispatch } from "../../store/StoreRoot";
import { resetState } from "./_duck/registerSlice";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './styles.css';
import axios from "axios";


export const LoginForm = () => {
  // Chakra color mode
  const titleColor = useColorModeValue("muni.celeste", "white");
  const [dni, setDni] = useState("");
  const dispatch: AppDispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [pinNumber, setPinNumber] = useState("");
  const [VisibleAlertPinError, setVisibleAlertPinError] = useState(false);
  const navigate = useNavigate();
  const { loading, error: hasError } = useSelector((resp: any) => resp.profile);
  const {
    isOpen: isOpenProblemModal,
    onOpen: onOpenProblemModal,
    onClose: onCloseProblemModal,
  } = useDisclosure();

  const toast = useToast();
  const toastIdRef = React.useRef();

  useEffect(() => {
    if (!hasError.isPinError) {
      if (hasError.message && hasError.status !== 403) {
        setDescriptionError(hasError.message);
        onOpen();
        setPassword("");
      }

      if (hasError.status === 403) {
        setCounter(300);
        onOpenModal();
      }
    } else {
      setDescriptionError(hasError.message);
      setVisibleAlertPinError(true);
      setPinNumber("");
      setTimeout(() => {
        setVisibleAlertPinError(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);


  const [counter, setCounter] = useState(0);
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
  const {
    isOpen: isOpenPolitica,
    onOpen: onOpenPolitica,
    onClose: onClosePolitica,
  } = useDisclosure();

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  //@ts-ignore
  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };
  const pagination = {
    clickable: true,

  };


  const handleSubmit = async (e: any) => {
    e.preventDefault(); // Evita que la página se recargue al enviar el formulario
    // Recoge los valores de los inputs
    const formData = new FormData();

    // Añadir los valores de los inputs al FormData
    formData.append('nombre', e.target.nombre.value);
    formData.append('apellido', e.target.apellido.value);
    formData.append('correo', e.target.correo.value);
    formData.append('tmovil', e.target.telefono_movil.value);
    formData.append('titulo', 'PROBLEMA EN AUTENTICA - MI BAHIA');
    formData.append('dni', e.target.dni.value);
    formData.append('descripcion', e.target.descripcion.value);
    formData.append('cp', '8000');
    formData.append('calle_id', '937');
    formData.append('altura', '65');
    formData.append('categoria_id', JSON.stringify(23));
    formData.append('subcategoria_id', JSON.stringify(154));
    try {
      const response = await axios.post(
        `https://incidencias.bahia.gob.ar/api/solicitud`,
        formData,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      // Extrae el número de reclamo de la respuesta
      const numeroReclamo = response.data.nro_reclamo;

      // Si la solicitud fue exitosa
      toast({
        title: "Solicitud enviada.",
        description: `Hemos recibido tu solicitud. Tu número de reclamo es: ${numeroReclamo}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Cerrar el modal después de enviar
      onCloseProblemModal();

    } catch (error: any) {
      // Manejo de errores
      toast({
        title: "Error al enviar.",
        description: error.response?.data?.message || "Ocurrió un error al enviar tu solicitud.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} bg={"muni.celeste"} justify={"center"} spacing={10} direction={"column"} display={{ base: 'none', md: 'flex' }} align={"center"}>
          <Box w={400}>
            <Swiper
              spaceBetween={90}
              pagination={pagination}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination, Navigation]}

            >
              <SwiperSlide style={{ display: 'flex', height: "auto", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                <Image src={"/slider.png"} />
                <Text fontSize='3xl' fontWeight={'bold'} style={{ color: ' white', }} width={"100%"} textAlign={"center"}>Registrate y validá tus
                  datos por única vez</Text>
              </SwiperSlide>
              <SwiperSlide style={{ display: 'flex', height: "auto", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                <Image src={"/slider2.png"} />
                <Text fontSize='3xl' fontWeight={'bold'} style={{ color: ' white', }} width={"100%"} textAlign={"center"}>Accedé a todos los servicios
                  en un único portal</Text>
              </SwiperSlide>
              <SwiperSlide style={{ display: 'flex', height: "auto", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                <Image src={"/slider3.png"} />
                <Text fontSize='3xl' fontWeight={'bold'} style={{ color: ' white', }} width={"100%"} textAlign={"center"}>Realizá tus consultas
                  y trámites online</Text>
              </SwiperSlide>

            </Swiper>
          </Box>

        </Stack>
        <Flex flex={1} bg={"white"} justify={"center"}>
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{ userSelect: "none" }}
            w={{ base: "100%", md: "80%", lg: "50%" }}
          >
            <Flex
              direction="column"
              w="100%"
              minH={"90vh"}
              justify={"space-between"}
              p="20px"
              mt={{ md: "0px", lg: "0px" }}
            >
              <Heading color={titleColor} fontSize="48px">
                <Box alignItems={"center"}>
                  <AutenticaIcon w={"100%"} h={[100, 130]} />
                </Box>

                <Text
                  mb="30px"
                  textAlign={"center"}
                  mt="6px"
                  color={useColorModeValue("blackAlpha.800", "white")}
                  fontWeight={400}
                  fontSize={{ base: "15px", md: "20px", lg: "16px" }}
                >
                  Accedé a tu portal en el Municipio de Bahía Blanca utilizando tu cuenta de ARCA, ANSES, Mi Argentina o con tu DNI a través del RENAPER.
                </Text>
              </Heading>
              <Collapse in={isOpen} animateOpacity>
                <Box
                  p="20px"
                  color="white"
                  mb="14px"
                  bg="red.500"
                  rounded="md"
                  shadow="md"
                >
                  <Text
                    fontWeight={"bold"}
                    fontSize={"14px"}
                    textAlign={"center"}
                  >
                    error: {descriptionError}
                  </Text>
                </Box>
              </Collapse>
              <FormControl>
                <Divider />
                <Link href="http://auth.mibahia.gob.ar/afiplogin/">
                  <Button
                    fontSize="10px"
                    type="submit"
                    color="white"
                    bg={"muni.verde"}
                    w="100%"
                    h="45"
                    mb="20px"
                    p={6}
                    mt="20px"
                    _hover={{
                      bg: "black",
                      color: "white",
                    }}
                    _active={{
                      bg: "black",
                      color: "white",
                    }}
                  >
                    <Image src="./logo_arca_blanco.png" w={"30%"} />
                  </Button>
                </Link>
                <Box position='relative' padding='2'>
                  <Divider />
                </Box>
                <Link href="http://auth.mibahia.gob.ar/miargentinalogin/">
                  <Button
                    fontSize="10px"
                    type="submit"
                    colorScheme="afip"
                    variant='outline'
                    w="100%"
                    h="45"
                    mb="20px"
                    p={6}
                    mt="20px"
                    _hover={{
                      bg: "black",
                      color: "white",
                    }}
                    _active={{
                      bg: "black",
                      color: "white",
                    }}
                  >
                    <Text fontWeight={"bold"} fontSize={"18px"}>
                      Mi Argentina
                    </Text>
                  </Button>
                </Link>

                <Link href="http://auth.mibahia.gob.ar/renaperlogin/">
                  <Button
                    fontSize="10px"
                    type="submit"
                    colorScheme="afip"
                    variant='outline'
                    w="100%"
                    h="45"
                    mb="20px"
                    p={6}
                    mt="20px"
                    _hover={{
                      bg: "black",
                      color: "white",
                    }}
                    _active={{
                      bg: "black",
                      color: "white",
                    }}
                  >
                    <Text fontWeight={"bold"} fontSize={"18px"}>
                      RENAPER
                    </Text>
                  </Button>
                </Link>
                <Link href="http://auth.mibahia.gob.ar/anseslogin/">
                  <Button
                    fontSize="10px"
                    type="submit"
                    colorScheme="afip"
                    variant='outline'
                    w="100%"
                    h="45"
                    mb="20px"
                    p={6}
                    mt="20px"
                    _hover={{
                      bg: "black",
                      color: "white",
                    }}
                    _active={{
                      bg: "black",
                      color: "white",
                    }}
                  >
                    <Text fontWeight={"bold"} fontSize={"18px"}>
                      ANSES
                    </Text>
                  </Button>
                </Link>

                <Divider />
                {/*         <Text textAlign="center" color="muni.celeste" mt={4}>
                  Si tenés problemas para registrarte, hacé{" "}
                  <Text
                    as="span"
                    fontWeight="bold"
                    onClick={onOpenProblemModal}
                    cursor="pointer"
                  >
                    click aquí
                  </Text>
                </Text> */}
              </FormControl>
              <FormControl textAlign={"center"}>
                <Box >
                  <Link
                    color={"gray.500"}
                    fontWeight="bold"
                    onClick={() => onOpenPolitica()}
                  >
                    Politica de privacidad de datos
                  </Link>
                </Box>
                <Box>
                  <Link
                    href="https://mibahia.gob.ar/error"
                    color={"gray.500"}
                    fontWeight="bold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Resolución de problemas de registro / ingreso
                  </Link>
                </Box>
              </FormControl>

            </Flex>

          </Flex>
          <Box>
            <Drawer placement={"bottom"} onClose={onClosePolitica} isOpen={isOpenPolitica}>
              <DrawerOverlay />
              <DrawerContent >
                <DrawerHeader borderBottomWidth='1px' color={"muni.celeste"}>Politica de Privacidad de Datos</DrawerHeader>
                <DrawerCloseButton />
                <DrawerBody>
                  <Accordion allowMultiple>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            ALCANCE
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Estas políticas abarcan la Web del Municipio de Bahía Blanca (MBB), Aplicaciones Móviles, Aplicaciones en Facebook desarrollados por MBB y otros activos digitales (se entiende por “activo digital” a cualquier otra aplicación web, móvil, de e-mail, participativa o sitio web desarrollado por MBB) que en el futuro se implemente.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Las presentes Políticas de Privacidad tienen por objeto establecer los criterios para el uso de los diferentes activos digitales que el MBB, con domicilio en Calle Alsina 65 de nuestra ciudad, Provincia de Buenos Aires, pone a disposición de los ciudadanos.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          El MBB proporciona a los usuarios los recursos técnicos adecuados para que tomen conocimiento de las presentes Políticas de Privacidad y Protección de Datos Personales, y de cualquier otra información que pueda ser relevante, constituyendo el ingreso de datos personales o información confidencial en el sitio, la manifestación expresa de su consentimiento a la presente.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          El Usuario puede utilizar el sitio web con diversos propósitos: obtener información personal, efectuar trámites en línea, buscar y compartir información, y todos aquellos trámites que en el futuro el MBB desarrolle y ponga a disposición del ciudadano.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Al suministrar información, el Usuario asiste al Administrador en el constante proceso de mejora de los servicios disponibles y en el desarrollo de nuevos productos, siempre tendiendo a que el uso de los mismos se torne más sencillo y ágil para los Usuarios. Proporcionar datos personales supone la aceptación sin reservas de los términos y condiciones aquí establecidos.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Las presentes políticas describen:
                          a) La existencia del archivo, registro, base o banco de datos, electrónico o de cualquier otro tipo de que se trate y la identidad y domicilio de su responsable;
                          b) Cuáles son los datos recolectados, cómo son utilizados y la finalidad para la que serán tratados;
                          c) Quiénes pueden ser sus destinatarios o categorías de destinatarios;
                          d) El carácter obligatorio o facultativo de la respuesta a las preguntas que le sean formuladas;
                          e) Las consecuencias que deriven de proporcionar los datos, de la negativa de hacerlo o de la inexactitud de los mismos;
                          f) Cuáles son las opciones que el MBB ofrece en cuanto a la facultad y modo de ejercer los derechos de acceso, rectificación, actualización y supresión de los datos.
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            DATOS RECOLECTADOS POR MBB                                        </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        La recolección de datos se realiza para mejorar los servicios ofrecidos por el MBB.
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            USO GENERAL SIN REGISTRO                                   </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Datos sobre el dispositivo: Podremos recoger datos específicos sobre tu dispositivo (como, por ejemplo, el modelo de equipo, la versión del sistema operativo, los identificadores únicos y los datos sobre la red móvil).
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Datos sobre tu ubicación física: Al utilizar un activo digital de MBB que pueda registrar tu ubicación física, podremos llevar a cabo la recolección y el tratamiento de datos acerca de tu ubicación real como, por ejemplo, las señales de GPS enviadas por un dispositivo móvil. También podremos utilizar diferentes tecnologías para determinar la ubicación, como los datos de los sensores del dispositivo que proporcionen, por ejemplo, información sobre los puntos de acceso Wi-Fi y las antenas de telefonía móvil más cercanos.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Cookies e identificadores anónimos: Utilizamos diferentes tecnologías para recoger y almacenar datos cuando accedes a un servicio de MBB, incluyendo el envío de una o varias cookies o de identificadores anónimos a tu dispositivo.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Google Analytics: Recolectamos información estadística anónima para evaluación y mejoras del servicio a través de un servicio de terceros (Google Analytics). Usamos cookies para guardar parte de esta información en el dispositivo del usuario. Esto incluye remarketing con Google Analytics, informes de impresiones de la Red de Display de Google, informes de datos demográficos y de intereses de Google Analytics, y servicios integrados que necesitan Google Analytics para recoger datos con fines publicitarios, por ejemplo, a través de identificadores y cookies de publicidad.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          La información personal del Usuario que opta por registrarse puede contener campos obligatorios.
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            REGISTRO Y CREACIÓN DE CUENTA EN LA WEB                                   </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        La información personal del Usuario que opta por registrarse puede contener los siguientes campos obligatorios: a) Nombres, b) Apellidos, c) Número de CUIL o Pasaporte, d) Género, e) Dirección de correo electrónico, f) Fecha de nacimiento, g) Nacionalidad, h) Provincia, i) Localidad, j) Teléfono móvil, k) Dirección, l) Código postal. El usuario acreditará identidad ingresando a través de los siguientes proveedores nacionales: ARCA, RENAPER, MiArgentina y ANSES.
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            SOBRE EL USO DE LA INFORMACIÓN PERSONAL                                   </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Los datos recolectados a través de los formularios de contacto públicos podrán ser utilizados para:
                        </Text>
                        <Text as="ul" marginLeft="1.5rem" marginBottom="0.28cm">
                          <li>Satisfacer y optimizar los servicios solicitados y brindados por MBB.</li>
                          <li>Ofrecer información relevante para los ciudadanos.</li>
                          <li>Otros servicios potenciales que se puedan agregar.</li>
                          <li>Personalizar el contenido visto por el usuario.</li>
                          <li>Obtener métricas de uso y niveles de servicio en MBB.</li>
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          El MBB hará todo lo posible por garantizar la confidencialidad de la información personal solicitada, y de aquellos otros datos que así lo requieran, intentando que terceros ajenos al mismo no puedan acceder a ella. La información personal no será vendida o transferida a otras organizaciones, y no se entregará a empresas de publicidad.
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            SOBRE LA ADMINISTRACIÓN DE LA INFORMACIÓN PERSONAL POR PARTE DEL USUARIO                                   </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Al prestar nuestros servicios, protegeremos los datos procurando que no puedan ser eliminados de forma accidental o intencionada. Por este motivo, aunque el usuario elimine sus datos de nuestros servicios, es posible que no destruyamos de inmediato las copias residuales almacenadas en nuestros servidores activos ni los datos almacenados en nuestros sistemas de seguridad. Independientemente de eliminar su Cuenta, no se eliminarán las transacciones o trámites realizados entre el usuario y MBB.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Es importante señalar que son los usuarios quienes garantizan y responden, en cualquier caso, por la veracidad, exactitud, vigencia y autenticidad de la información personal facilitada, y se comprometen a mantenerla debidamente actualizada.
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            SEGURIDAD                                   </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          El MBB mantendrá absoluta confidencialidad de los datos personales. Nos esforzamos por proteger al MBB y a nuestros usuarios frente a cualquier modificación, divulgación o destrucción no autorizada de los datos que conservamos o frente al acceso no autorizado a los mismos. En particular: Encriptamos muchos de nuestros servicios mediante el protocolo TSL/SSL siempre que esté disponible. Revisamos nuestra política en materia de recolección, almacenamiento y tratamiento de datos, incluyendo las medidas de seguridad físicas, para impedir el acceso no autorizado a nuestros sistemas.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Limitamos el acceso de los contratistas, los agentes y los empleados del MBB a la información personal que deben procesar para el Municipio y nos aseguramos de que cumplan las estrictas obligaciones de confidencialidad contractuales y de que estén sujetos a las condiciones disciplinarias pertinentes o al despido si no cumplen dichas obligaciones.
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            MODIFICACIONES                                   </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          El MBB podrá modificar en cualquier momento los términos y condiciones de estas Políticas de Privacidad y confidencialidad y/o las prácticas de envío de e-mails.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Si decidimos introducir algún cambio material a nuestras Políticas de Privacidad, te notificaremos a través de un aviso al inicio de la página.
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            JURISDICCIÓN Y DERECHO APLICABLE                                   </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Esta Política de Privacidad rige en todos y cada uno de sus extremos por las leyes de la República Argentina y de la Provincia de Buenos Aires.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          En caso de surgir cualquier diferencia, desacuerdo, controversia o conflicto respecto de la interpretación o cumplimiento de estos Términos y Condiciones, el conflicto será dirimido por la Justicia en lo Contencioso Administrativo de la ciudad de Bahía Blanca, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponder.
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' fontWeight={"bold"}>
                            RESPONSABILIDAD                                  </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Responsabilidad por causas ajenas:
                          En ningún caso, el MBB será responsable de algún daño, incluyendo, sin límite, daños, pérdidas o gastos directos, indirectos, inherentes o consecuentes, que surjan en relación con el uso de los activos digitales del MBB o imposibilidad de uso, en relación con cualquier falla en el vencimiento, error, omisión, interrupción, defecto, demora en la operación o transmisión, virus de computadora o falla del sistema de línea, aún en el caso de que el MBB o sus representantes fueran informados sobre la posibilidad de dichos daños, pérdidas o gastos.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          El MBB no controla ni garantiza la ausencia de virus ni de otros elementos en los Contenidos que puedan producir alteraciones en su sistema informático (software y hardware) o en los documentos electrónicos y ficheros almacenados en su sistema informático.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Se presume que cuando un usuario accede a los activos digitales lo hace bajo su responsabilidad y que, por lo tanto, acepta totalmente el contenido de los términos y condiciones de uso del portal web.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          El MBB no se responsabiliza por el actuar de otros sitios web a los cuales se puede acceder por medio del SITIO, por lo que recomendamos la lectura de la Política de Privacidad de cada uno de ellos.
                        </Text>
                        <Text textAlign="justify" lineHeight="108%" marginBottom="0.28cm">
                          Responsabilidad por actualización y modificaciones de los datos personales
                          El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita conforme establecen la ley nacional N° 25.326 y Ley provincial N° 14.214.
                          Sin embargo, es el usuario quien debe informar o reclamar sus modificaciones y actualización, el MBB no actuará de oficio en ningún momento.
                          En consecuencia y de acuerdo a lo manifestado, el MBB no será responsable por la actualización de los datos personales de los ciudadanos siempre y cuando los usuarios no hayan reclamado su modificación o actualización.
                        </Text>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Box>
        </Flex>

      </Stack >
      <Modal isOpen={isOpenProblemModal} onClose={onCloseProblemModal} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="lg" boxShadow="lg" p={4}>
          <ModalHeader textAlign="center" fontWeight="bold" fontSize="2xl" color="blue.600">
            Problemas para registrarte
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Nombre</FormLabel>
                  <Input name="nombre" placeholder="Nombre" focusBorderColor="blue.500" />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Apellido</FormLabel>
                  <Input name="apellido" placeholder="Apellido" focusBorderColor="blue.500" />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>DNI</FormLabel>
                  <Input name="dni" placeholder="Documento de identidad" focusBorderColor="blue.500" />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Correo</FormLabel>
                  <Input name="correo" type="email" placeholder="Correo" focusBorderColor="blue.500" />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Teléfono Móvil</FormLabel>
                  <Input name="telefono_movil" placeholder="Teléfono Móvil" focusBorderColor="blue.500" />
                </FormControl>
                <Divider />

                <FormControl isRequired>
                  <FormLabel>Descripción</FormLabel>
                  <Textarea name="descripcion" placeholder="Descripción" focusBorderColor="blue.500" resize="vertical" />
                </FormControl>
              </Stack>
              <ModalFooter justifyContent="center">
                <Button type="submit" colorScheme="blue" size="lg" w={"100%"}>
                  Enviar
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>



    </>
  );
};



